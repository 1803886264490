import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import ListGroup from 'react-bootstrap/ListGroup'

import Layout from '../../components/layout'
import ResourcesNav from '../../components/resources-nav'

export default class Games extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const metaDescription = `Play the free ${siteTitle} games! Test your number sense with Fill-It, Pattern Recognition, and the Matching Game.`
    return (
      <Layout pageTitle="Games" description={metaDescription}>
        <ResourcesNav path={this.props.path} />
        <h1>Games</h1>
        <hr />
        <ListGroup>
          <ListGroup.Item as="a" href="/resources/games/fill-it/">
            Fill It
          </ListGroup.Item>
          <ListGroup.Item
            as="a"
            href="/resources/games/pattern-recognition/Number-Recongition-1_4.html"
          >
            Pattern Recognition 1-4
          </ListGroup.Item>
          <ListGroup.Item
            as="a"
            href="/resources/games/pattern-recognition/Number-Recongition-0_8.html"
          >
            Pattern Recognition 0-8
          </ListGroup.Item>
          <ListGroup.Item
            as="a"
            href="/resources/games/pattern-recognition/Number-Recongition-4_8.html"
          >
            Pattern Recognition 4-8
          </ListGroup.Item>
          <ListGroup.Item
            as="a"
            href="/resources/games/pattern-recognition/Number-Recongition-8_10.html"
          >
            Pattern Recognition 8-10
          </ListGroup.Item>
          <ListGroup.Item
            as="a"
            href="/resources/games/pattern-recognition/Number-Recongition-0_10.html"
          >
            Pattern Recognition 0-10
          </ListGroup.Item>
          <ListGroup.Item as="a" href="/resources/games/matching-game/">
            Matching Game
          </ListGroup.Item>
        </ListGroup>
      </Layout>
    )
  }
}

export const Query = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
  }
`
